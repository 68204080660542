<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              :to="{ name: 'promotion-wizard' }"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Add New</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="promotions"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="id"
        show-empty
        striped
        :borderless="false"
        outlined
        empty-text="No matching records found"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-tabs>
            <b-tab title="Merchants">
              <b-tabs vertical nav-wrapper-class="nav-vertical">
                <b-tab title="Included">
                  <b-card-text>
                    {{ row.item.include_merchant }}
                  </b-card-text>
                </b-tab>
                <b-tab title="Excluded">
                  <b-card-text>
                    {{ row.item.exclude_merchant }}
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab title="APPs">
              <b-tabs vertical nav-wrapper-class="nav-vertical">
                <b-tab title="Included">
                  <b-card-text>
                    {{ row.item.include_app }}
                  </b-card-text>
                </b-tab>
                <b-tab title="Excluded">
                  <b-card-text>
                    {{ row.item.exclude_app }}
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab title="Methods">
              <b-tabs vertical nav-wrapper-class="nav-vertical">
                <b-tab title="Included">
                  <b-card-text>
                    {{ row.item.include_channel }}
                  </b-card-text>
                </b-tab>
                <b-tab title="Excluded">
                  <b-card-text>
                    {{ row.item.exclude_channel }}
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-tab>
          </b-tabs>
        </template>

        <!-- Column: User -->
        <template #cell(merchant)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.logo_url"
                :text="data.item.merchant_name"
                variant="primary"
              />
            </template>
            <b-link
              :to="{ name: 'merchant-apps', query: { merchant_no: data.item.merchant_no } }"
              class="font-weight-bold d-block"
            >
              {{ data.item.merchant_name }}
            </b-link>
            <small class="text-muted">@{{ data.item.merchant_no }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div
            v-if="data.item.status === 1"
            class="text-nowrap"
          >
            <feather-icon
              icon="PauseIcon"
              size="16"
              class="cursor-pointer mx-1"
              @click="changeStatus(data.item.id, 0)"
            />
          </div>
          <div
            v-if="data.item.status === 0"
            class="text-nowrap"
          >
            <feather-icon
              icon="PlayIcon"
              size="16"
              class="cursor-pointer mx-1"
              @click="changeStatus(data.item.id, 1)"
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="Trash2Icon"
              size="16"
              class="cursor-pointer mx-1"
              @click="deleteConfirm(data.item)"
            />
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalPromotions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 mr-3"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BFormInput, BButton, BMedia, BAvatar, BLink, BPagination, BFormCheckbox,
  BTabs, BTab, BCardText,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import usePromotionsJs from './promotions'

const {
  fetchActivityList,
  updatePromotionStatus,
  removePromotion,
} = usePromotionsJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BFormCheckbox,
    BTabs,
    BTab,
    BCardText,
  },
  data() {
    return {
      promotions: [],
      dataMeta: {
        from: 1,
        to: 1,
        of: 0,
      },
      perPage: 10,
      tableFields: [
        'show_details',
        { key: 'name', label: 'NAME' },
        { key: 'target_currency', label: 'CURRENCY' },
        { key: 'type', label: 'TYPE' },
        { key: 'config_detail', label: 'DETAIL' },
        { key: 'start_time', label: 'START' },
        { key: 'end_time', label: 'END' },
        { key: 'status', label: 'STATUS' },
        'actions',
      ],
      currentPage: 1,
      totalPromotions: 0,
      filter: null,
      filterOn: ['id', 'name', 'include_merchant', 'include_app', 'include_channel'],
    }
  },
  watch: {
    currentPage() {
      this.recalMetaData()
    },
  },
  created() {
    this.getPromotionList()
  },
  methods: {
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    showToast(title, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            text,
            variant: 'warning',
          },
        },
        {
          position: 'top-center',
        },
      )
    },
    recalMetaData() {
      this.dataMeta.from = this.currentPage * 10 - (10 - 1)
      this.dataMeta.to = this.totalPromotions - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalPromotions
      this.dataMeta.of = this.totalPromotions
    },
    getPromotionList() {
      fetchActivityList(
        promotions => {
          this.promotions = promotions
          this.totalPromotions = promotions.length
          this.recalMetaData()
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
    onFiltered(filteredItems) {
      this.totalPromotions = filteredItems.length
      this.currentPage = 1
      this.recalMetaData()
    },
    changeStatus(id, status) {
      const params = {
        id,
        status,
      }
      updatePromotionStatus(
        params,
        () => {
          this.showToast('Success', 'Status Updated.')
          this.getPromotionList()
        },
        () => {},
      )
    },
    deleteConfirm(item) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete(item)
        }
      })
    },
    delete(item) {
      removePromotion(
        item.id,
        () => {
          this.showAlert('success', 'Deleted!', `${item.name} has deleted.`)
          this.getPromotionList()
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>
