import store from '@/store'
import promotionStoreModule from '../../promotionStoreModule'
import merchantStoreModule from '../../merchantStoreModule'
import channelStoreModule from '../../channelStoreModule'

export default function promotionsJs() {
  const PROMOTION_STORE_MODULE_NAME = 'promotion'
  const MERCHANT_STORE_MODULE_NAME = 'merchant'
  const CHANNEL_STORE_MODULE_NAME = 'channel'

  // Register module
  if (!store.hasModule(PROMOTION_STORE_MODULE_NAME)) store.registerModule(PROMOTION_STORE_MODULE_NAME, promotionStoreModule)
  if (!store.hasModule(MERCHANT_STORE_MODULE_NAME)) store.registerModule(MERCHANT_STORE_MODULE_NAME, merchantStoreModule)
  if (!store.hasModule(CHANNEL_STORE_MODULE_NAME)) store.registerModule(CHANNEL_STORE_MODULE_NAME, channelStoreModule)

  const fetchActivityList = (success, fail) => {
    store
      .dispatch('promotion/fetchActivityList')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const addPromotion = (params, success, fail) => {
    store
      .dispatch('promotion/addPromotion', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updatePromotionStatus = (params, success, fail) => {
    store
      .dispatch('promotion/updatePromotionStatus', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const removePromotion = (params, success, fail) => {
    store
      .dispatch('promotion/removePromotion', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchants = (success, fail) => {
    store
      .dispatch('merchant/fetchMerchants')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          const merchants = []
          data.forEach(item => {
            const merchant = { label: item.merchant_name, value: item.merchant_no }
            merchants.push(merchant)
          })

          success(merchants)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchantByNo = (params, success, fail) => {
    store
      .dispatch('merchant/fetchMerchantByNo', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchApps = (success, fail) => {
    store
      .dispatch('merchant/fetchApps')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          const apps = []
          data.forEach(item => {
            const app = { label: item.app_name, value: item.app_id }
            apps.push(app)
          })

          success(apps)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchElements = (success, fail) => {
    store
      .dispatch('channel/fetchChannelElements')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          const methods = []
          data.methods.forEach(item => {
            const method = { label: item.type_name, value: item.type_name }
            methods.push(method)
          })
          const currencies = []
          data.currencies.forEach(item => {
            const currency = { label: item.code, value: item.code }
            currencies.push(currency)
          })

          const result = {}
          result.methods = methods
          result.currencies = currencies
          success(result)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  return {
    fetchActivityList,
    addPromotion,
    updatePromotionStatus,
    removePromotion,
    fetchMerchants,
    fetchApps,
    fetchElements,
    fetchMerchantByNo,
  }
}
