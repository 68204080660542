import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 获取数据
    fetchActivityList() {
      return new Promise((resolve, reject) => {
        axios.post('/activity/list')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },
    // 获取商户APP级联数据
    fetchCascaderItems() {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/getMerchantAppsMenu')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },
    // ADD
    addPromotion(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.put('/activity/create', item)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },
    // UPDATE
    updatePromotionStatus(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.post(`/activity/${item.id}/${item.status}`)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },
    // DELETE
    removePromotion(ctx, itemId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/activity/${itemId}`)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },
  // actions end
  },
}
